import React, { Component } from "react"
import "./search.css"

const API_URL = 'https:api.themoviedb.org/3/discover/movie?sort_by=popularity.desc&api_key=1be956e95c4b5e4c503e088b231c7780&page=1'
const SEARCH_API = 'https:api.themoviedb.org/3/search/movie?api_key=1be956e95c4b5e4c503e088b231c7780&query="'
const IMG_PATH = 'https://image.tmdb.org/t/p/w1280'

class Search extends Component {
  state = {
    searchValue: "",
    movie: []
  }

  handleOnChange = event => {
    this.setState({ searchValue: event.target.value })
  }

  handleSearch = () => {
    this.movieQuery(this.state.searchValue)
  }

  movieQuery = searchInput => {
    var searchUrl = `${SEARCH_API}${searchInput}"`

    fetch(searchUrl)
      .then(response => {return response.json()})
      .then(jsonData => {this.setState({ movie: jsonData.results })})
      .catch(err => {console.log('Error fetching data' + err)})
  }

  render() {
    return (
      <div id="root">
        <h1>Movie Lookup</h1>
        <span id="about">About</span>
        <div id="credits">This product uses the TMDb API but is not endorsed or certified by TMDb.</div>
        <header>
          <form id="form">
            <input
          type="text"
          id = "search"
          className="search"
          placeholder="Search"
          onChange={event => this.handleOnChange(event)}
          value={this.state.searchValue}
        />
          </form>
          &nbsp;
          <button onClick={this.handleSearch}>Enter</button>
        </header>
        
        
        {this.state.movie ? (
          <div id="movie-info" className="main">
            {this.state.movie.map((movie, index) => (
              <div className="movie" key={index}>
                <img src={IMG_PATH+movie.poster_path} alt={movie.title} />
                <h3>{movie.title}</h3>
                <span className={this.getClassByRate(movie.vote_average)}>{movie.vote_average}</span>
                
                <div className="overview">
                  <h3>Overview</h3>
                  {movie.overview}
                </div>
              </div>

            ))}
          </div>
        ) : (
          <p>Try Again</p>
        )}
      </div>
    );
  }

getClassByRate = vote => {
  if(vote >=8) {
    return 'green'
  } else if(vote >= 5) {
    return 'orange'
  } else if (vote >= 1) {
    return 'red'
  } else {
    return 'white'
  }
}
}

export default Search
