import project1 from '../assets/christopher-gower-m_HRfLhgABo-unsplash.jpg';
import project2 from '../assets/octavian-dan-iY6QMkP66mI-unsplash.jpg';
import project3 from '../assets/gary-lopater-dOOGrK3zcUc-unsplash.jpg';
import project4 from '../assets/shoppingBags.jpg';

const PROJECTS = [
    {
        id: 1,
        title: 'Example React Application',
        description: 'A React App that I built, involving JS and core web dev concepts!',
        link: 'https://github.com/SRGHBELLE/react-portfolio-site',
        image: project1
    },
    {
        id: 2,
        title: 'My Dating App Project',
        description: 'A REST API that I build from scratch with GET and POST requests!',
        link: 'https://github.com/SRGHBELLE/DatingApp',
        image: project2
    },
    {   id: 3,
        title: 'My Travel Site Project',
        description: 'Visit travel site project where I showcase my adventures.',
        link: 'https://github.com/SRGHBELLE/MyTravels',
        image: project3
    },
    {   id: 3,
        title: 'ProShop Project',
        description: 'A shopping site using MERN (MongoDB-Express-React-Node).',
        link: 'https://github.com/SRGHBELLE/proshop-sr',
        image: project4
    }
];

export default PROJECTS;