import React, { Component } from 'react';
import Projects from './Projects';
import SocialProfiles from './SocialProfiles';
import Title from './Title';
import profile from '../assets/SandraByDebbie2.png';

class App extends Component {
  state = { displayBio: false }

  toggleDisplayBio = () => {
    this.setState({ displayBio: !this.state.displayBio })
  }

  render() {
    return (
      <div>
        <img src={profile} alt='profile' className='profile' />
        <h1>Hello!</h1>
        <p>My name is Sandra.</p>
        <Title />
        <p>Integrity + Accountability + Focus === Stellar Results</p>
        {this.state.displayBio ? (
          <div>
            <p>I live in the West Orange area, and I code every day.</p>
            <p>
              I am a Marvel Movie Enthusiast!
            </p>
            <p>
              I enjoy Interior Design!
            </p>
            <p>
              Besides coding, I also love music, walking and seeing new
              places.
            </p>
            <button onClick={this.toggleDisplayBio}>Show Less</button>
          </div>
        ) : (
          <div>
            <button onClick={this.toggleDisplayBio}>Read More</button>
          </div>
        )}
        <hr />
        <Projects />
        <hr />
        <SocialProfiles />
      </div>
    )
  }
}

export default App;
