import emailIcon from '../assets/email_icon.png';
import githubIcon from '../assets/github_icon.png';
import linkedinIcon from '../assets/linkedin_icon.png';
import twitterIcon from '../assets/twitter_icon.png';

const SOCIAL_PROFILES = [
  {
    id: 1,
    link: 'https://github.com/SRGHBELLE',
    image: githubIcon
  },
  {
    id: 2,
    link: 'https://www.linkedin.com/in/sandra-riggins',
    image: linkedinIcon
  },
  {
    id: 3,
    link: 'https://twitter.com/Stechlady',
    image: twitterIcon
  },
];

export default SOCIAL_PROFILES;