import React from 'react';
import ReactDOM from 'react-dom';
import { Router, Switch, Route } from 'react-router-dom';
import createBrowserHistory from 'history/createBrowserHistory';
import App from './components/App';
import MovieLookup from './projects/movie-lookup';
import MusicMaster from './projects/music-master';
import Header from '/components/Header';
import './index.css';

ReactDOM.render(
  <Router history={createBrowserHistory()}>
    <switch>
      <Route exact path='/' render={() => <Header><App /></Header>} />

      <Route path='/movie-lookup' render={() => <Header><MovieLookup /></Header>} />
      <Route path='/music-master' render={() => <Header><MusicMaster /></Header>} />
    </switch>
  </Router>,
  document.getElementById('root')
);
